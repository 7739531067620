<template>
    <div class="company-select">
        <label for="clientUser">{{ $t('companySelect.label') }}</label>
        <div class="dropdown">
            <div class="selected-company" :class="{ 'has-error': error }" @click="toggleDropdown">
                <span v-if="selectedCompany">{{ selectedCompany.name }}</span>
                <span v-else>{{ $t('companySelect.selectCompany') }}</span>
            </div>
            <div v-if="isDropdownOpen" class="dropdown-content">
                <input
                    v-model="searchQuery"
                    type="text"
                    :placeholder="$t('companySelect.searchPlaceholder')"
                    class="search-input"
                />
                <ul class="dropdown-menu">
                    <li
                        v-for="company in filteredCompanies"
                        :key="company.attributes.id"
                        @click="selectCompany(company)"
                    >
                        <span>{{ company.attributes.name }}</span>
                    </li>
                    <li v-if="filteredCompanies.length === 0" class="no-results">
                        {{ $t('companySelect.noResults') }}
                    </li>
                </ul>
            </div>
            <span v-if="error" class="error-message">{{ error }}</span>
        </div>
    </div>
</template>

<script>
    import ClientService from '../../Client/services/client.service';

    export default {
        props: {
            selectedCompanyID: {
                type: Array,
            },
            error: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                companies: [],
                selectedCompany: null,
                isDropdownOpen: false,
                searchQuery: '',
                clientService: new ClientService(),
            };
        },
        async created() {
            await this.fetchClientCompanies();
        },
        computed: {
            filteredCompanies() {
                if (!this.searchQuery) {
                    return this.companies;
                }
                return this.serchedCompany();
            },
        },
        methods: {
            async fetchClientCompanies() {
                try {
                    const { data } = await this.clientService.getWithFilters({ disable_pagy: true });
                    this.companies = data.data;
                } catch (error) {
                    console.log('User request error', error);
                }
            },
            serchedCompany() {
                const scope = this.companies.filter(company => {
                    if (company.attributes.name) {
                        return company.attributes.name.toLowerCase().includes(this.searchQuery.toLowerCase());
                    }
                });
                console.log(scope);
                return scope;
            },
            toggleDropdown() {
                this.isDropdownOpen = !this.isDropdownOpen;
            },
            selectCompany(company) {
                this.selectedCompany = company.attributes;
                this.isDropdownOpen = false;
                this.searchQuery = '';
                this.$emit('selected-company', company.attributes.company_id);
            },
        },
    };
</script>

<style scoped lang="scss">
    .company-select {
        margin-bottom: 20px;
        font-family: 'Arial', sans-serif;
    }

    label {
        font-weight: bold;
        margin-bottom: 5px;
        color: #555;

        .theme-dark & {
            color: #ffa500;
        }
    }

    span {
        margin-left: 5px;
    }

    .dropdown {
        position: relative;
    }

    .selected-company {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 12px;
        border: 1px solid #ddd;
        border-radius: 8px;
        background-color: #fff;
        transition: border-color 0.3s, box-shadow 0.3s;

        .theme-dark & {
            background-color: #333;
            border-color: #555;
            color: #ffa500;
        }

        &:hover {
            border-color: #4caf50;

            .theme-dark & {
                border-color: #ffa500;
            }
        }
    }

    .dropdown-content {
        position: relative;
        width: 100%;
    }

    .search-input {
        width: 100%;
        padding: 10px;
        margin: 5px 0;
        border: 1px solid #ddd;
        border-radius: 8px;
        box-sizing: border-box;
        background-color: #fff;
        transition: border-color 0.3s, box-shadow 0.3s;

        .theme-dark & {
            background-color: #333;
            border-color: #555;
            color: #ffa500;
        }

        &:focus {
            border-color: #4caf50;
            box-shadow: 0 0 10px rgba(76, 175, 80, 0.2);

            .theme-dark & {
                border-color: #ffa500;
                box-shadow: 0 0 10px rgba(255, 165, 0, 0.4);
            }
        }
    }

    .dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 8px;
        margin-top: 5px;
        max-height: 200px;
        overflow-y: auto;
        z-index: 1;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);

        .theme-dark & {
            background-color: #333;
            border-color: #555;
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
        }
    }

    .dropdown-menu li {
        padding: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
        transition: background-color 0.3s, color 0.3s;
        color: #333;

        .theme-dark & {
            color: #ffa500;
        }
    }

    .dropdown-menu li:hover {
        background-color: #f0f0f0;

        .theme-dark & {
            background-color: #222;
        }
    }

    .no-results {
        padding: 10px;
        text-align: center;
        color: #999;

        .theme-dark & {
            color: #ccc;
        }
    }

    .has-error {
        border-color: #e74c3c;

        .theme-dark & {
            border-color: #ff4c4c;
        }
    }

    .error-message {
        color: #e74c3c;
        font-size: 12px;
        margin-top: 5px;

        .theme-dark & {
            color: #ff4c4c;
        }
    }
</style>
