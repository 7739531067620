import axios from 'axios';

class Service {
    save(data) {
        return axios.post('api/v1/client_services', data);
    }

    getAll() {
        return axios.get('api/v1/client_services');
    }

    update(id, data) {
        return axios.put(`api/v1/client_services/${id}`, data);
    }

    getById(id) {
        return axios.get(`api/v1/client_services/${id}`);
    }

    destroy(id) {
        return axios.delete(`api/v1/client_services/${id}`);
    }
}

export default Service;
